import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
  Switch,
  Select,
  MenuItem,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useAppState } from "../../contexts/AppContext";
import CancelButton from "../../components/SaveModalButtons/CancelButton";
import SaveButton from "../../components/SaveModalButtons/SaveButton";
import EditButton from "../../components/SaveModalButtons/EditButton";
import { Alert } from "../../utils/alert";
import moment from "moment";
import style from "./styles.module.css";
import ".././style.css";
import { useSourcesAPI } from "../../hooks/useSourcesAPI";
import useEscapeClose from "../../hooks/CloseModal/CloseModal";

const SaveSourceModal = ({ handleCloseSaveModal, defaultValues }) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [apiKey, setApiKey] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState(null);
  const [limit, setLimit] = useState("");
  const [limitInterval, setlimitInterval] = useState("");
  const [checked, setChecked] = useState(true);
  const [includeStorage, setIncludeStorage] = useState(false);
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Source created successfully",
  });

  const firstRun = useRef(true);

  const { config } = useAppState();
  const requestInterval = useMemo(
    () => config?.config?.source?.requestInterval,
    [config]
  );

  useEffect(() => {
    if (defaultValues) {
      setName(defaultValues.name);
      setApiKey(defaultValues.apiKey);
      setDate(defaultValues.expirationAt);
      setLimit(defaultValues.requestLimit);
      setlimitInterval(defaultValues.requestLimitInterval);
      setChecked(defaultValues.active);
      setIncludeStorage(defaultValues.includeStorage || false);
    }
  }, [defaultValues]);

  useEscapeClose(handleCloseSaveModal);

  const { postSource, editSource, generateKey } = useSourcesAPI(setApiKey);

  useEffect(() => {
    if (firstRun.current && !defaultValues?.apiKey) {
      generateKey.mutate();
      firstRun.current = false;
    }
    // eslint-disable-next-line
  }, [generateKey]);

  const validation = () => {
    if (name && apiKey) {
      return false;
    } else {
      return true;
    }
  };

  const EditValidation = () => {
    if (
      defaultValues.name === name &&
      moment(new Date(defaultValues.expirationAt)).format("YYYY-MM-DD") ===
        date &&
      limit === defaultValues.requestLimit &&
      limitInterval === defaultValues.requestLimitInterval &&
      checked === defaultValues.active &&
      includeStorage === defaultValues.includeStorage
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };
  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };
  const handleChangeApiKey = (event) => {
    setApiKey(event.target.value);
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };
  const handleChangeIncludeStorage = (event) => {
    setIncludeStorage(event.target.checked);
  };
  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  };
  const handleChangeInterval = (event) => {
    setlimitInterval(event.target.value);
  };
  const copyToClipboard = (item) => {
    navigator.clipboard.writeText(item).then(() => {
      handleOpenAlert("success", "Key copied to clipboard");
    });
  };

  const handlePostSources = () => {
    postSource.mutate({
      name: name,
      active: checked,
      includeStorage: includeStorage,
      apiKey: apiKey,
      expirationAt: date ? moment(date).format("YYYY-MM-DD") : null,
      requestLimit: limit ? +limit : null,
      requestLimitInterval: limitInterval ? limitInterval : null,
    });
  };

  const handleEditSources = () => {
    editSource.mutate({
      data: {
        name: name,
        active: checked,
        includeStorage: includeStorage,
        expirationAt: date ? moment(date).format("YYYY-MM-DD") : null,
        requestLimit: limit ? +limit : null,
        requestLimitInterval: limitInterval ? limitInterval : null,
      },
      id: defaultValues._id,
    });
  };

  const handleGenerateKey = () => {
    generateKey.mutate();
  };

  return (
    <>
      <Box className={style.modal}>
        <Typography variant={"h1"} className={style.title}>
          {defaultValues ? "Edit" : "Create"} Source
        </Typography>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>Name</Typography>
          <TextField
            placeholder={"Enter name"}
            value={name}
            onChange={handleChangeName}
            variant="outlined"
          />
        </Box>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>API Key</Typography>
          <TextField
            InputLabelProps={{ shrink: false }}
            value={apiKey}
            placeholder={"API Key"}
            variant="outlined"
            onChange={handleChangeApiKey}
            disabled={defaultValues}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ paddingRight: 0 }}
                  onClick={() => copyToClipboard(apiKey)}
                  aria-label="copy"
                >
                  <ContentCopyIcon />
                </IconButton>
              ),
            }}
          />
        </Box>
        {!defaultValues && (
          <Button
            variant="refresh"
            color="primary"
            size="large"
            onClick={handleGenerateKey}
          >
            <RefreshIcon />
            Refresh
          </Button>
        )}
        <Box
          sx={defaultValues && { marginTop: "25px" }}
          className={style.create_source_item}
        >
          <Typography variant={"subtitle2"}>Expiration Data</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              placeholder="For desktop"
              value={date}
              minDate={tomorrow}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box className={style.create_source_item}>
          <Typography variant={"subtitle2"}>Request Limit</Typography>
          <TextField
            placeholder="Request Limit"
            variant="outlined"
            value={limit}
            onChange={handleChangeLimit}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Select
                    variant="standard"
                    disableUnderline={true}
                    margin="normal"
                    value={limitInterval}
                    onChange={handleChangeInterval}
                  >
                    {requestInterval &&
                      Object.entries(requestInterval).map(([key, value]) => (
                        <MenuItem value={value.value}>{value.label}</MenuItem>
                      ))}
                  </Select>
                </InputAdornment>
              ),
            }}
          />
          <Box className={style.switch}>
            <Switch checked={checked} onChange={handleChangeChecked} />
            <Typography sx={{ margin: "-2px 0 0 10px" }}>Active</Typography>
          </Box>
          <Box className={style.switch}>
            <Switch
              checked={includeStorage}
              onChange={handleChangeIncludeStorage}
            />
            <Typography sx={{ margin: "-2px 0 0 10px" }}>
              Include Storage
            </Typography>
          </Box>
        </Box>
        <Box className={style.container_main_buttons}>
          <CancelButton handleCloseSaveModal={handleCloseSaveModal} />
          {defaultValues ? (
            <EditButton
              handlePost={handleEditSources}
              validation={EditValidation}
            />
          ) : (
            <SaveButton
              handlePost={handlePostSources}
              validation={validation}
            />
          )}
        </Box>
      </Box>
      <Snackbar
        open={alert.isShow}
        autoHideDuration={3500}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SaveSourceModal;
